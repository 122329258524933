// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require('./header')

import Plyr from 'plyr';

document.addEventListener('turbolinks:load', function() {
  Plyr.setup('video', {
    controls: [
    'play-large', // The large play button in the center
    // 'play', // Play/pause playback
    // 'progress', // The progress bar and scrubber for playback and buffering
    'volume', // Volume control
    // 'pip', // Picture-in-picture (currently Safari only)
    // 'airplay', // Airplay (currently Safari only)
    'fullscreen', // Toggle fullscreen
    ],
    ratio: '16:9',
    autopause: true,
  })

  Array.from(document.querySelectorAll('video')).forEach(player => {
    player.addEventListener('playing', function(event) {
      event
        .currentTarget
        .closest('.content')
        .querySelector('.description')
        .classList.add('hide')
    })

    player.addEventListener('pause', function(event) {
      event
        .currentTarget
        .closest('.content')
        .querySelector('.description')
        .classList.remove('hide')
    })
    player.addEventListener('ended', function(event) {
      event
        .currentTarget
        .closest('.content')
        .querySelector('.description')
        .classList.remove('hide')
    })
  })
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)